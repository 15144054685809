import { Link } from "react-router-dom";

const SlideButton = (props) => {
  return (
    <Link
      to={props.to}
      className={`${props.className} bg-white text-black rounded-[10px] py-[5px] px-[20px] font-semibold text-[20px] capitalize`}
    >
      {props.text}
    </Link>
  );
};

export default SlideButton;
