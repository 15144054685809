import ProjectDetail from "../layout/project/ProjectDetail";

const Project = (props) => {
  return (
    <ProjectDetail
      projects={props.projects}
      images={props.projects_additional_images}
    />
  );
};

export default Project;
