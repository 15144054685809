import image1 from "../../images/home-slides/image1.jpg";
import logo from "../../images/logo.svg";

const sliderData = [
  {
    id: 0,
    description:
      "Hamze Brass Center is a vibrant company with a lot of experience in many different service and product offerings.",
    buttonText: "Read More",
    imageUrl: image1,
    arrow: true,
    bannerText: "A Name You Can Trust",
    bannerImage: logo,
  },
];

export default sliderData;
