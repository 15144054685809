import { Row } from "react-bootstrap";
import SlideButton from "../button/SlideButton";
import arrow from "../../../images/home-slides/arrow-down.svg";
import LogoBanner from "./LogoBanner";

const SingleBanner = (props) => {
  const handleScrollDown = () => {
    const targetPosition = window.scrollY + window.innerHeight;
    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Row className={`${props.index === 0 ? "!h-[100vh]" : "!h-[40vh] md:!h-[100vh]"} !w-full !mx-0`}>
        <div
          className={`!w-full! h-full !relative ${props.index === 0 ? "!bg-cover" : "!bg-cover"}`}
          style={{
            backgroundImage: `url(${props.image})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <img src={props.image} alt="..." className="!absolute !left-0 !top-0 !h-full !object-cover" /> */}
          <div className="black-overlay"></div>
          <div className="!z-10 !absolute left-[7%] top-[45%] md:top-[55%] !transform !-translate-y-1/2 !max-w-[70%] md:!max-w-[80%]">
            <p className="text-white font-semibold leading-[40px] sm:leading-[70px] text-[25px] sm:text-[40px] md:text-[60px]">
              {props.description}
            </p>
            {props.index !== 0 && (
              <SlideButton
                to={`/service/${props.link}`}
                text={props.btnText}
                className="!mt-3 md:!mt-10"
              />
            )}
          </div>
          {props.arrow && (
            <div className="!z-10 !flex !flex-col !items-center !absolute !left-1/2 !transform !-translate-x-1/2 !bottom-5">
              <p className="!text-white !mb-0">What We Do</p>
              <img
                src={arrow}
                className="!cursor-pointer !mt-2 md:mt-0"
                alt="What We Do"
                onClick={handleScrollDown}
              />
            </div>
          )}
        </div>
      </Row>
      <Row>
        <LogoBanner title={props.bannerText} logo={props.bannerLogo} />
      </Row>
    </div>
  );
};

export default SingleBanner;
