import { Container, Col, Row } from "react-bootstrap";
import logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {

  function getCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }

  return (
    <Container
      fluid
      //  md:!h-1/2
      className="!pt-10 !bg-[#222222] !mx-0 !pb-16 !px-3 md:!px-20 md:!pt-14 !relative"
    >
      <Row className="!w-full !gap-14 md:!gap-0">
        <Col xs={12} sm={12} md={4}>
          <div className="!flex !flex-col !items-start">
            <Link to="/">
              <img src={logo} alt="Hamza" className="!cursor-pointer" />
            </Link>
            <p className="!text-white !font-bold !leading-[22px] !mt-5">
              Hamze Brass Center is a vibrant company with a lot of experience
              in many different service and product offerings.
            </p>
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          className="!flex !items-start md:!justify-center"
        >
          <div className="!flex !flex-col !items-start !justify-start">
            <p className="text-[#F7B844] !font-bold !leading-5 !text-[20px]">
              Contact us
            </p>
            <div className="!flex !flex-col !items-start !justify-start !pl-2 !text-white">
              <div className="!flex !items-center !justify-around">
                <i className="fa fa-mobile" style={{ color: "#ffffff" }} />
                <a
                  href={`tel:${"+961 78 970 899"}`}
                  className="!mb-0 !ml-2 !text-white"
                >
                  +961 78 970 899
                </a>
              </div>
              <div className="!flex !items-center !justify-around !mt-3">
                <i
                  className="fa-solid fa-envelope"
                  style={{ color: "#ffffff" }}
                ></i>
                <a
                  href={`mailto:${"mohamad.shihadi@hotmail.com"}`}
                  className="!mb-0 !ml-2 !text-white"
                >
                  mohamad.shihadi@hotmail.com
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          className="!flex !items-start md:!justify-center"
        >
          <div className="!flex !flex-col !items-start !justify-start">
            <p className="text-[#F7B844] !font-bold !leading-5 !text-[20px]">
              Social Media
            </p>
            <div className="!flex !flex-col !items-start !justify-start !pl-2 !text-white">
              <div className="!flex !items-center !justify-around">
                <i
                  className="fa-brands fa-facebook"
                  style={{ color: "#ffffff" }}
                />{" "}
                <a
                  href={"/"}
                  className="!mb-0 !ml-2 text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </div>
              <div className="!flex !items-center !justify-around !mt-3">
                <i
                  className="fa-brands fa-instagram"
                  style={{ color: "#ffffff" }}
                ></i>
                <a
                  href={"/"}
                  className="!mb-0 !ml-2 text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </div>
              <div></div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="!w-full !absolute !bottom-3 !left-1/2 !transform !-translate-x-1/2 md:!realtive !pt-7 !mt-5">
        <p className="!text-white !text-center !text-[12px]">
          Copyrights © {getCurrentYear()} - HAMZE BRASS ALL RIGHTS RESERVED.
        </p>
      </Row>
    </Container>
  );
};

export default Footer;
