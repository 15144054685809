import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const HomeContact = () => {
  return (
    <Container
      fluid
      //  md:!h-1/2
      className="!h-full bg-[#4A4A4A] !pt-14 !pb-10 !text-white"
    >
      <div className="!h-full !flex !flex-col !items-center !justify-center !max-w-[624px] !m-auto">
        <h1 className="!font-bold md:!text-[40px] !leading-8">
          Let’s Build Together
        </h1>
        <p className="!text-center !font-bold !max-w-[70%] md:!max-w-full !text-[25px] !leading-8 !mt-6">
          Like What You See? Tell us about your project. We don’t just build
          your project, we build your future!
        </p>
        <Link
          to="/contact-us"
          className="!mt-6 !text-white !px-10 !py-3 !border-white !border-2 !rounded-none
        hover:!text-black hover:!bg-[#F7B844] hover:!border-[#F7B844]
        !font-bold !text-[18px]"
        >
          Contact
        </Link>
      </div>
    </Container>
  );
};

export default HomeContact;
