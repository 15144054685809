import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
//
import right from "../../../images/projects/right-arrow.png";
import left from "../../../images/projects/left-arrow.png";

const BackdropSwiper = (props) => {
  return (
    <div
      className="backdrop !flex !items-center !justify-center"
      onClick={() => props.backdropClick()}
    >
      <div className="!relative !w-full !h-full !flex !items-center !justify-center">
        <Swiper
          slidesPerView={1}
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: ".custom-prev",
            nextEl: ".custom-next",
          }}
          pagination={true}
          className="!h-[80%] !w-[90%] !m-auto"
          spaceBetween={30}
          initialSlide={props.activeIndex}
          loop={true}
        >
          {props.images?.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image.file_path}
                alt={index}
                className="!w-full !h-full !object-contain"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <img
          src={left}
          alt="left"
          className="custom-prev !cursor-pointer !z-[300] !absolute !top-[65%] !left-[30%] md:!top-1/2 !transform md:!-translate-y-1/2 md:!left-20"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <img
          src={right}
          alt="right"
          className="custom-next !cursor-pointer !z-[300] !absolute !top-[65%] !right-[30%] md:!top-1/2 !transform md:!-translate-y-1/2 md:!right-20"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
    </div>
  );
};

export default BackdropSwiper;
