import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
//
import ProjectCard from "../../ui/cards/ProjectCard";
//
const WorkForm = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [projects, setProjects] = useState([]);

  const categoryHandler = (id) => {
    if (id === 0) {
      setProjects(props.projects);
    } else {
      const selectedProjects = props.projects?.filter(
        (p) => p.r_category_id === id
      );
      setProjects(selectedProjects);
    }
    setSelectedCategory(id);
  };

  useEffect(() => {
    setProjects(props.projects);
  }, [props.projects]);

  return (
    <Container fluid className="!px-5 sm:!px-10 md:!px-20 !pt-32 !bg-[#f5f5f5]">
      <Col>
        <Row>
          <div className="!flex !flex-col !w-[600px]">
            <h1 className="!font-extrabold lg:!text-[40px] lg:!leading-[48px]">
              Check Our Latest Work
            </h1>
            <p className="lg:!text-[28px] lg:!leading-[33px]">
              Here we show you our latest projects for our trusted clients
            </p>
          </div>
        </Row>
        <Row>
          <Swiper
            slidesPerView={3}
            breakpoints={{
              220: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              460: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 200,
              },
              1400: {
                slidesPerView: 5,
              },
            }}
            className="!ma-uto !flex !justify-center !items-center"
          >
            <SwiperSlide
              tag="p"
              className={`pt-3 !relative  ${
                selectedCategory === 0
                  ? "!text-[#581c1c] !scale-105 !font-bold"
                  : "!text-[#212529]"
              } !cursor-pointer !w-[100px] !min-w-[100px] !text-[15px] !p-3 !text-left`}
              onClick={() => categoryHandler(0)}
            >
              All
            </SwiperSlide>
            {props.categories?.map((p) => (
              <SwiperSlide
                tag="p"
                className={`pt-3 ${
                  selectedCategory === p.id
                    ? "!text-[#581c1c] !scale-105 !font-bold"
                    : "!text-[#212529]"
                } !cursor-pointer !whitespace-nowrap !min-w-min !text-[15px] !text-left`}
                key={p.id}
                onClick={() => categoryHandler(p.id)}
              >
                {p.name}
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="!grid !grid-cols-1 md:!grid-cols-2 !place-items-center !gap-5">
            {projects?.map((slide) => (
              <ProjectCard
                key={slide.id}
                index={slide.id}
                bg={slide.main_image}
                title={slide.name}
                link={`/projects/${slide.slug}`}
              />
            ))}
          </div>
        </Row>
      </Col>
    </Container>
  );
};

export default WorkForm;
