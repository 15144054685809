import { Container } from "react-bootstrap";
import SingleBanner from "../../../ui/banners/SingleBanner";
import sliderData from "../../../data/MainSlider";
import logo from "../../../../images/logo.svg";

const ImagesBanner = (props) => {
  return (
    <Container fluid className="!px-0 !mx-0">
      <div className="!grid !grid-cols-1 !w-full">
        <SingleBanner
          key={sliderData[0]?.id}
          index={sliderData[0]?.id}
          image={sliderData[0]?.imageUrl}
          link={sliderData[0]?.slug}
          btnText={sliderData[0]?.buttonText}
          description={sliderData[0]?.description}
          arrow={sliderData[0]?.arrow}
          bannerText={sliderData[0]?.bannerText}
          bannerLogo={sliderData[0]?.bannerImage}
        />
        {props.data?.map((slide) => {
          const catName = props.categories?.find(
            (cat) => cat.id === slide.r_category_id
          );
          return (
            <SingleBanner
              key={slide.id}
              index={slide.id}
              image={slide.main_image}
              link={slide.slug}
              btnText={slide.button_text}
              description={catName?.name}
              bannerText={slide.banner_text}
              bannerLogo={logo}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default ImagesBanner;
