const LogoBanner = (props) => {
  return (
    <div className="!px-3 md:!px-44 !z-10 logoBannerBg !w-full !h-[5vh] md:!h-[10vh] !flex !items-center !justify-evenly">
      {/* <img src={props.logo} className="!object-contain !h-[50px] !w-[60px]" alt="Hamze" /> */}
      <p className="!text-center !mb-0 !text-[14px] sm:!text-[35px] !font-bold !text-[#c2c2c2]">{props.title}</p>
      {/* <img src={props.logo} className="!object-contain !h-[50px] !w-[60px]" alt="Hamze" /> */}
    </div>
  );
};

export default LogoBanner;
