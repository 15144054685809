import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ProjectCard = (props) => {
  return (
    <Link to={props.link}>
      <Card className="!cursor-pointer xl:!w-[606px] lg:!min-h-[369px] lg:!max-h-[369px] !bg-transparent !border-none !rounded-none">
        <Card.Img
          variant="top"
          src={props.bg}
          className="md:!min-h-[80%] md:!max-h-[80%] !rounded-none"
        />
        <Card.Body className="!px-0">
          <Card.Title className="!text-[21px] !font-semibold !leading-[25px] !text-[#212529]">
            {props.title}
          </Card.Title>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default ProjectCard;
