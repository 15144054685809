import { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SlideButton from "../../ui/button/SlideButton";

const WorkCategory = (props) => {
  const { category } = useParams();

  const [data, setData] = useState([]);

  const getData = (title) => {
    const content = props.data?.find((s) => s.slug === title);
    setData(content);
  };

  useEffect(() => {
    if (category && props.data) {
      getData(category);
    }
  }, [category, props.data]);

  return (
    <Container fluid className="!bg-[#f5f5f5] !mx-0 !px-0">
      <Col>
        <Row className="!h-[45vh] md:!h-[60vh] !w-full !px-0 !mx-0">
          <div
            className="!w-full !h-full !relative"
            style={{
              backgroundImage: `url(${data?.main_image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="black-overlay"></div>
            <div className="!z-10 !absolute left-[7%] top-[55%] md:top-[50%] !transform !-translate-y-1/2">
              <p className="text-white font-semibold leading-[40px] sm:leading-[70px] text-[25px] sm:text-[40px] md:text-[60px]">
                {
                  props.categories
                    ?.filter((cat) => cat.id === data?.r_category_id)
                    .map((filteredCat) => filteredCat.name)
                }
                .
              </p>
            </div>
            <SlideButton
              to={`/projects/${data?.first_project}`}
              text="View Projects"
              className="!z-10 !absolute !bottom-5 !left-1/2 !transform !-translate-x-1/2"
            />
          </div>
        </Row>
        <Row className="!px-5 md:!px-20 !py-10">
          <h1 className="!text-[#202020] !font-bold md:!leading-[30px]">
            {data?.name}
          </h1>
          <p
            className="!mt-3 !font-medium !leading-[30px] md:!leading-[40px]"
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></p>
        </Row>
      </Col>
    </Container>
  );
};

export default WorkCategory;
